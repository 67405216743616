import { mq } from "../../../../cssInJs";

export function getExTextPanelStyle(){
    return{
        exTextPanel : mq({
            overflow:'hidden',
            maxHeight:'0',
            transition: 'all 0.8s',
            '&.expand' : {
                maxHeight:'10000px'
            }
        })
    }
}