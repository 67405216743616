/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useRef } from 'react';
import utils from '../../../../utils';
import cmsUtils from '../../cmsUtils';
import { handleElementLink } from '../../../../components';
import { useRouter } from '../../../../components';
import { renderHtml } from '../../html/view/renderHtml';
import { getExTextPanelStyle } from './exTextPanelStyle';

export function ExTextPanel(props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const { history } = useRouter();
    let content = cmsOption.isCmsEdit ? cmsUtils.payload(item, 'Content') : cmsUtils.payload(item, 'Content').replace("__version__", "sample");

    const cssClassExPanel = utils.classNames('cms_item', cmsOption.isCmsEdit && 'expand', item.cssClass || item.anchorName || '');
    const cssClass = utils.classNames('cms_item', 'htmlPanel', cmsOption.isCmsEdit && 'html');

    function handleClick(e) { 
        let target = "";
        if(e.target.closest('a')){
          target = e.target.closest('a').getAttribute('target');
        }
        const processed = (target === "_blank" ? handleElementLink(e.target, null) : handleElementLink(e.target));
        if (processed.handled) {
          e.preventDefault();
          e.stopPropagation();
        } else if (processed.linkInfo && processed.linkInfo.type === 'internal') {
          e.preventDefault();
          e.stopPropagation();
          history.push(processed.linkInfo.url)
        }
        else {}
    }
    const s = getExTextPanelStyle();
    const htmlBlock = useRef(null);
    return( 
        <div css = {s.exTextPanel} data-anchor-status = "collapse" data-cms-item-anchor = {item.anchorName} className={cssClassExPanel}>
            <div ref={htmlBlock} className={cssClass} data-cms-html="true"
                data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
                onClick={(e) => handleClick(e)}>
                {renderHtml(content, cmsOption)}
            </div>
        </div>
    )
}